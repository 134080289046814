.separator{
  width: 100px;
  height: 3px;
  margin: auto;
  background-color: #0E64a2;
}

.input-group{
  color: black;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  width: 200px;

}

.input-group label{
  color: black;
  font-size: 20px;
  align-self: center;
  width: 80px;
}

.input-group input{
  color: black;
  border: 1px solid #c2c1c1;
  border-radius: 20px;
  font-size: 16px;
  padding: 6px 0;
  text-align: center;
  width: 100px;
}

.main{
  background-color: #f8f9fa;

}
.container{
  background-color: #ffffff;
  margin: auto;
  width: 80%;
  /* height: 100vh; */
}

.row{
  padding: 10px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.col{
  margin: 10px;

}

.title{
  color: #333333;
  padding-top: 20px;
  padding-bottom: 10px;
  text-align: center;
}

.subTitle{
  color: #3a3a3a;
  padding: 20px 0;
  text-align: center;
}

.inputForm{
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  padding: 20px;
  width: 450px;
  margin: auto;
}

.titleForm{
  margin-bottom: 10px ;
}
.divider{
  background-color: #c2c1c1;
  height: 2px;
  margin-bottom: 20px ;
  width: 100%;
}

.cardboard{
  border: 1px solid #c2c1c1;
  border-radius: 10px;
  width: 560px;
}

.lineXTest{
  font-size: 12px;
}

.titleCustom{
  color: #3a3a3a;
  font-size: 14px;
  text-align: center;
  margin: 10px;
}

@media screen and (max-width: 900px) {
  .container{
    width: 100%;
  }

  .inputForm{
    width: 560px;
  }
}